<template>
  <div class="finishedCondition">

    <div class="flex-box">
      <div class="left">
        <el-button type="info">下载</el-button>
      </div>
    </div>

    <el-table :data="lookScoreDetailList" style="width: 100%">
        <el-table-column type="expand">
            <template slot-scope="scope">

                <el-form v-for="(item,index) in scope.row.list" :key="index" label-position="left" inline class="demo-table-expand">
                    <el-form-item label="评分教师：">
                        <!-- <span>{{ item.rater }}</span> -->
                        <el-tag type="success">{{ item.rater }}</el-tag>
                    </el-form-item>
                    <el-form-item label="分数：">
                        <!-- <span>{{ item.score }}</span> -->
                        <el-tag>{{ item.score }}</el-tag>
                    </el-form-item>
                </el-form>

            </template>
        </el-table-column>

        

        <el-table-column
        label="被评分教师"
        prop="manager_name"
        align="center"
        ></el-table-column>
        <!-- <el-table-column
        label="评分教师"
        prop="rater"
        align="center"
        ></el-table-column> -->
        <el-table-column
        label="分数"
        prop="score"
        align="center"
        ></el-table-column>
        <!-- <el-table-column
        label="总分"
        prop="statusStr"
        align="center"
        ></el-table-column> -->

    </el-table>

    <el-pagination
      layout="prev,pager,next"
      background
      :page-count="page.pageCount"
      :page-size="page.currentPage"
      @current-change="queryTask"
    ></el-pagination>

    
  </div>
</template>

<script>

export default {
  name: "finishedCondition",
  data() {
    return {
        page: { pageCount: 1, currentPage: 0 },
        score_id:Number(this.$route.query.score_id),

        // tea_id:Number(this.$route.query.tea_id),
        // 评分详情表单
        // scoreDetailForm:{
        //   rater:'',
        //   score:'',
        //   opinion:''
        // },
        lookScoreDetailList:[],

    };
  },
  created() {
    this.lookScoreDetail()
  },
  methods: {
    // 查看评分详情
    lookScoreDetail(){
      // return
      this.$post(`/base/queryScoringSystemRand.do`,{
        score_id:this.score_id,
        // tea_id:this.tea_id
      }).then(res=>{
        console.log(res)
        this.lookScoreDetailList = res.data
      })
    },
  },
};
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}

.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>
